import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-portfolio-footer",
  templateUrl: "./portfolio-footer.component.html",
  styleUrls: ["./portfolio-footer.component.scss"],
})
export class PortfolioFooterComponent implements OnInit, OnChanges {
  @Input() instagramLink: string;
  @Input() facebookLink: string;
  @Input() logo: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.facebookLink = changes?.facebookLink?.currentValue;
    this.instagramLink = changes?.instagramLink?.currentValue;
    this.logo = `${environment.urlServer}${changes?.logo?.currentValue}`;
  }

  ngOnInit() {}
}

