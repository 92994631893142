import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  Gallery,
  GalleryItem,
  ThumbnailsPosition,
  ImageSize,
} from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { ContentService } from "src/app/services/content.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-portfolio-gallery",
  templateUrl: "./portfolio-gallery.component.html",
  styleUrls: ["./portfolio-gallery.component.scss"],
})
export class PortfolioGalleryComponent implements OnInit, OnChanges {
  @Input() products: any[];
  @Input() tags: any[];
  @Input() meta: {
    pagination: {
      total: number;
      page: number;
      pageSize: number;
      pageCount: number;
    };
  };

  pagination: number = 1;
  currentPage: number = 1;

  loading: boolean = false;
  expandedIndex: number = -1;

  allProducts: any[] = [];

  public customizer: any = "all";
  items: GalleryItem[];

  urlServer = environment.urlServer;

  constructor(
    public gallery: Gallery,
    public lightbox: Lightbox,
    private contentService: ContentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.products = changes?.products?.currentValue;
    this.tags = changes?.tags?.currentValue;
    this.meta = changes?.meta?.currentValue;
    if (this.meta) {
      this.pagination = Math.ceil(this.meta.pagination.total / 8);
    }
    this.allProducts = [...this.products];
  }

  ngOnInit() {
    const lightboxRef = this.gallery.ref("lightbox");
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
    });
    lightboxRef.load(this.items);
  }
  
  toggleDescription(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = -1; // Si el mismo elemento está expandido, colapsa
    } else {
      this.expandedIndex = index; // Expandir el nuevo elemento
    }
  }

  async openGallery(val) {
    this.loading = true;
    this.customizer = val;
    let products: any;
    this.currentPage = 1;
    if (val === "all") {
      products = await this.contentService.getProducts({
        page: 1,
        pageSize: 8,
      });
    } else {
      products = await this.contentService.getProducts(
        {
          page: 1,
          pageSize: 8,
        },
        {
          etiqueta_producto: {
            nombre: {
              eq: val,
            },
          },
        }
      );
    }
    this.products = products?.data?.productos?.data;
    this.meta = products?.data?.productos?.meta;
    this.pagination = Math.ceil(this.meta.pagination.total / 8);
    this.loading = false;
  }

  async onPageChange(page: number) {
    this.currentPage = page;
    const products = await this.contentService.getProducts(
      {
        page,
        pageSize: 8,
      },
      this.customizer !== "all"
        ? {
            etiqueta_producto: {
              nombre: {
                eq: this.customizer,
              },
            },
          }
        : {}
    );

    this.products = products.data.productos.data;
  }
}

