<!-- footer start -->
<footer class="resume copyright copyright-bg inner-pages-footer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="link link-horizontal  text-center mb-3">
                    <img src={{logo}} alt="" class="img-fluid" width="54">
                </div>
            </div>
            <!-- <div class="col-12">
                <div class="link link-horizontal">
                    <ul class="justify-content-center">
                        <li><a href="javascript:void(0)" class="copyright-text">home</a></li>
                        <li><a href="javascript:void(0)" class="copyright-text">couple</a></li>
                        <li><a href="javascript:void(0)" class="copyright-text">invitation</a></li>
                        <li><a href="javascript:void(0)" class="copyright-text">album </a></li>
                        <li><a href="javascript:void(0)" class="copyright-text">friends say</a></li>
                        <li><a href="javascript:void(0)" class="copyright-text">gift</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-12">
                <div class="text-center">
                    <div class="social-link link-horizontal">
                        <ul class="justify-content-center">
                            <li><a href={{facebookLink}} class="copyright-text"><i class="fa fa-facebook"
                                        aria-hidden="true"></i></a></li>
                            <li><a href={{instagramLink}} class="copyright-text "><i class="fa fa-instagram"
                                        aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->
<app-tap-to-top></app-tap-to-top>