<!--services start-->
<section class="resume portfolio-section zoom-gallery filter-section">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title2">
                    <h6 class="font-primary borders main-text text-uppercase"><span>Nosotros</span></h6>
                    <div class="sub-title">
                        <h2 class="title-text text-capitalize text-center">Nuestros servicios </h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 service-container" style="margin-top: 2.5rem;" *ngFor="let data of services">
                <div class="service text-center">
                    <div class="img-block">
                        <img [src]="urlServer + data?.attributes?.icono?.data?.attributes?.url" alt="logo"
                            class="service-img img-fluid" width="46">
                    </div>
                    <div class="service-feature">
                        <h4 class="feature-text text-center">{{data?.attributes?.titulo}}</h4>
                        <p class="text-center">{{data?.attributes?.description}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!--services end-->