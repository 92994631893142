<div class="row">
    <div class="col-12">
        <div class="filter-container isotopeFilters">
            <ul class="list-inline filter">
                <li [ngClass]="{'active': customizer == 'all' }"><a (click)="openGallery('all')">Todos </a></li>
                <li [ngClass]="{'active': customizer === tag?.attributes?.nombre}" *ngFor="let tag of tags">
                    <a (click)="openGallery(tag?.attributes?.nombre)">
                        {{tag?.attributes?.nombre}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="isotopeContainer row" gallerize>
    <figure class="col-xl-3 col-sm-6" itemprop="associatedMedia" *ngFor="let product of products; let i = index">
        <h3 style="font-weight: bolder;">{{product.attributes.nombre }}</h3>
        <a href="javascript:void(0)" itemprop="contentUrl">
            <img [src]="urlServer + product.attributes.imagen?.data?.attributes?.url" class="img-fluid" />
        </a>
        <p style="font-weight: bolder;">{{product.attributes.precio | currency}}</p>
        <div style="margin-top: 4px;">
            <p style="opacity: 0.7; display: inline;">
                {{ (expandedIndex === i || product.attributes.descripcion.length < 90) ? product.attributes.descripcion
                    : (product.attributes.descripcion | slice:0:90) }} </p>
                    <span style="font-weight: bolder; cursor: pointer;"
                        *ngIf="product.attributes.descripcion.length >= 90" (click)="toggleDescription(i)"> {{
                        (expandedIndex === i || product.attributes.descripcion.length < 90) ? 'Ver menos' : '...Ver más'
                            }}</span>
        </div>
        <hr>
    </figure>
</div>


<!-- pagination Start -->
<div class="pagination_sec">
    <div class="content_detail__pagination cdp">
        <ul>
            <li *ngFor="let item of ' '.repeat(pagination).split(''); let idx = index">
                <a href="javascript:void(0)" [ngClass]="{'active': idx + 1 === currentPage}" class="cdp_i"
                    (click)="onPageChange(idx + 1)">
                    {{ idx + 1 }}
                </a>
            </li>
        </ul>

    </div>
</div>
<!-- pagination End -->