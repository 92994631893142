import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-portfolio-copyright",
  templateUrl: "./portfolio-copyright.component.html",
  styleUrls: ["./portfolio-copyright.component.scss"],
})
export class PortfolioCopyrightComponent implements OnInit {
  year: number;

  constructor() {}

  ngOnInit() {
    this.year = new Date().getFullYear()
  }
}

