<!--megamenu section Start-->
<header class="dark loding-header custom-scroll nav-lg">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav class="d-flex align-items-center justify-content-center">
                    <a href="/"><img src="{{logo}}" alt="" width="54"></a>
                    <!-- <app-menu></app-menu> -->
                </nav>
            </div>
        </div>
    </div>
</header>
<!--megamenu section end-->