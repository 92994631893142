import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-portfolio-header",
  templateUrl: "./portfolio-header.component.html",
  styleUrls: ["./portfolio-header.component.scss"],
})
export class PortfolioHeaderComponent implements OnInit, OnChanges {
  @Input() logo: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.logo = `${environment.urlServer}${changes.logo.currentValue}`;
  }

  ngOnInit() {}
}

