import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-portfolio-breadcrumb",
  templateUrl: "./portfolio-breadcrumb.component.html",
  styleUrls: ["./portfolio-breadcrumb.component.scss"],
})
export class PortfolioBreadcrumbComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() backgroundImage: string;
  @Input() logo: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = changes?.title?.currentValue;
    this.backgroundImage = `${environment.urlServer}${changes?.backgroundImage?.currentValue}`;
    this.logo = `${environment.urlServer}${changes?.logo?.currentValue}`;
  }

  ngOnInit() {}
}

