import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-portfolio-services",
  templateUrl: "./portfolio-services.component.html",
  styleUrls: ["./portfolio-services.component.scss"],
})
export class PortfolioServicesComponent implements OnInit, OnChanges {
  @Input() services: any[];

  urlServer = environment.urlServer;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.services = changes.services.currentValue;
  }

  ngOnInit() {}
}

