<div class="agency">
    <app-portfolio-header [logo]="headerLogo" />
    <app-portfolio-breadcrumb [title]="breadcrumbTitle" [backgroundImage]="breadcrumbBackgroundImage"
        [logo]="breadcrumbLogo" />
    <app-portfolio-services [services]="services" />
    <section class="resume portfolio-section zoom-gallery filter-section" style="padding-top: 40px;">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="title title2">
                        <h6 class="font-primary borders main-text text-uppercase"><span>Catálogo</span></h6>
                        <div class="sub-title">
                            <div class="">
                                <h2 class="title-text">Últimos productos</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-portfolio-gallery class="p-4col-width" class="p-4col-width" [tags]="tags" [products]="products"
                [meta]="productsMeta" />
        </div>
    </section>
    <!-- <app-gallery></app-gallery> -->
    <app-portfolio-footer [facebookLink]="footerFacebookLink" [instagramLink]="footerInstagramLink"
        [logo]="footerLogo"></app-portfolio-footer>
    <app-portfolio-copyright />
</div>