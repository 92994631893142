import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortfolioMetroComponent } from './layouts/portfolio-metro/portfolio-metro.component'
export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PortfolioMetroComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
