import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { lastValueFrom, map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  constructor(private apollo: Apollo) {}

  getContent() {
    const query = gql`
      query {
        index {
          data {
            attributes {
              headerLogo {
                data {
                  attributes {
                    url
                  }
                }
              }
              backgroundImageHeader {
                data {
                  attributes {
                    url
                  }
                }
              }
              tituloLogo {
                data {
                  attributes {
                    url
                  }
                }
              }
              tituloPrincipal
              facebookLink
              instagramLink
              footerLogo {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `;

    const observable$ = this.apollo.query<{ index: any }>({
      query,
      fetchPolicy: "no-cache",
    });

    return lastValueFrom(observable$);
  }

  getServices() {
    const query = gql`
      query {
        servicios {
          data {
            attributes {
              icono {
                data {
                  attributes {
                    url
                  }
                }
              }
              titulo
              description
            }
          }
        }
      }
    `;

    const observable$ = this.apollo.query<{ servicios: any }>({
      query,
      fetchPolicy: "no-cache",
    });

    return lastValueFrom(observable$);
  }

  getTags() {
    const query = gql`
      query {
        etiquetaProductos {
          data {
            attributes {
              nombre
            }
          }
        }
      }
    `;

    const observable$ = this.apollo.query<{ etiquetaProductos: any }>({
      query,
      fetchPolicy: "no-cache",
    });

    return lastValueFrom(observable$);
  }

  getProducts(
    pagination: { page: number; pageSize: number },
    filters: object = {}
  ) {
    const query = gql`
      query Productos(
        $pagination: PaginationArg
        $filters: ProductoFiltersInput
      ) {
        productos(pagination: $pagination, filters: $filters) {
          meta {
            pagination {
              total
              page
              pageSize
              pageCount
            }
          }
          data {
            attributes {
              imagen {
                data {
                  attributes {
                    url
                  }
                }
              }
              nombre
              descripcion
              precio
              etiqueta_producto {
                data {
                  attributes {
                    nombre
                  }
                }
              }
            }
          }
        }
      }
    `;

    const observable$ = this.apollo.query<{ productos: any }>({
      query,
      fetchPolicy: "no-cache",
      variables: {
        pagination,
        filters,
      },
    });

    return lastValueFrom(observable$);
  }
}

