import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ColorScssService } from "../../shared/service/color-scss.service";
import { Main } from "src/app/interfaces/interfaces";

import { ContentService } from "../../services/content.service";

@Component({
  selector: "app-portfolio-metro",
  templateUrl: "./portfolio-metro.component.html",
  styleUrls: ["./portfolio-metro.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PortfolioMetroComponent implements OnInit {
  products: any[] = [];
  productsMeta: {
    pagination: {
      total: number;
      page: number;
      pageSize: number;
      pageCount: number;
    };
  };
  services: any[] = [];
  tags: any[] = [];
  content: Main;
  headerLogo: string;
  breadcrumbTitle: string;
  breadcrumbBackgroundImage: string;
  breadcrumbLogo: string;
  footerFacebookLink: string;
  footerInstagramLink: string;
  footerLogo: string;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    public colorPicker: ColorScssService,
    private contentService: ContentService
  ) {}

  async ngOnInit() {
    const r: Main = await this.contentService.getContent();
    const s = await this.contentService.getServices();
    const t = await this.contentService.getTags();
    const p = await this.contentService.getProducts({ page: 1, pageSize: 8 });

    this.headerLogo =
      r?.data?.index?.data?.attributes?.headerLogo?.data?.attributes?.url;

    this.breadcrumbTitle = r?.data?.index?.data?.attributes?.tituloPrincipal;
    this.breadcrumbBackgroundImage =
      r?.data?.index?.data?.attributes?.backgroundImageHeader?.data?.attributes?.url;
    this.breadcrumbLogo =
      r?.data?.index?.data?.attributes?.tituloLogo?.data?.attributes?.url;

    this.services = s.data?.servicios?.data;

    this.tags = t.data?.etiquetaProductos?.data;

    this.products = p.data?.productos?.data;
    this.productsMeta = p.data?.productos?.meta;

    this.footerFacebookLink = r.data?.index?.data?.attributes?.facebookLink;
    this.footerInstagramLink = r.data?.index?.data?.attributes?.instagramLink;
    this.footerLogo =
    r.data?.index?.data?.attributes?.footerLogo?.data?.attributes?.url;

    this.colorPicker.setColorScheme("inner-page");
    this.title.setTitle(this.route.snapshot.data["title"]);
  }
}

