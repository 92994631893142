import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutsRoutingModule } from "./layouts-routing.module";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { GalleryModule } from "ng-gallery";
import { LightboxModule } from "ng-gallery/lightbox";
import { NgxSliderModule } from "ngx-slider-v2";

//Portfolio Layout
import { PortfolioMetroComponent } from "./portfolio-metro/portfolio-metro.component";
import { PortfolioHeaderComponent } from "./portfolio-metro/portfolio-header/portfolio-header.component";
import { PortfolioBreadcrumbComponent } from "./portfolio-metro/portfolio-breadcrumb/portfolio-breadcrumb.component";
import { PortfolioGalleryComponent } from "./portfolio-metro/portfolio-gallery/portfolio-gallery.component";
import { PortfolioFooterComponent } from "./portfolio-metro/portfolio-footer/portfolio-footer.component";
import { PortfolioCopyrightComponent } from "./portfolio-metro/portfolio-copyright/portfolio-copyright.component";
import { PortfolioServicesComponent } from "./portfolio-metro/portfolio-services/portfolio-services.component";

@NgModule({
  declarations: [
    PortfolioMetroComponent,
    PortfolioGalleryComponent,
    PortfolioHeaderComponent,
    PortfolioBreadcrumbComponent,
    PortfolioFooterComponent,
    PortfolioCopyrightComponent,
    PortfolioServicesComponent,
  ],
  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule,
    LightboxModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
  ],

  exports: [],
})
export class LayoutsModule {}

