<!--breadcrumb section start-->
<section class="portfolio-metro bg p-b-0" [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }">
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-sm-9 text-center ">
                    <div class="portfolio_section">
                        <div>
                            <h1 class="breadcrumb-text"><span class="bold-text">{{title}}</span></h1>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-sm-3 text-center">
                    <img alt="" class="img-fluid man" src="{{logo}}">
                </div>
            </div>
        </div>
    </div>
</section>
<!--breadcrumb section end-->